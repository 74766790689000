import React from "react";
import {graphql } from 'gatsby';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import Layout from "../components/layout"
import Seo from "../components/seo"
import DefaultLangSelector from "../components/LangSelector";
import { useTranslation } from 'gatsby-plugin-react-i18next';
import bgShadowSrc from '../images/bg-shadow.jpg';
import GalleryGrid from "../components/GalleryGrid";

const LangSelector = styled(DefaultLangSelector)`
  position: relative;
  top: auto;
  right: auto;
  margin-bottom: 50px;
  justify-content: center;

  @media(min-width: 768px) {
    margin-bottom: 20px;
    justify-content: flex-end;
  }
`;

const Content = styled.div`
`;

const Subtitle = styled.p`
  font-family: 'Playfair Display', Georgia, sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2.25rem;
  text-align: center;
  color: #303030;
  margin-bottom: 1.5rem;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: -20px;
    left: 50%;
    width: 60px;
    height: 5px;
    background-color: #ff940a;
    transform: translate(-50%,-50%);
  }

  @media(min-width: 768px) {
    font-size: 1.875rem;
    line-height: 3rem;
  }
`;

const LeftSubtitle = styled(Subtitle)`
  text-align: left;

  &:before {
    left: 0;
    transform: none;
  }
`;

const LeftSubtitleWithoutLine = styled(LeftSubtitle)`
  &:before {
    content: none;
  }
`;

const Section = styled.div`
  position: relative;
  background-color: #fff;
  background-image: url(${bgShadowSrc});
  background-repeat: repeat-y;
  background-position: top left;

  padding: 40px 1.5rem 110px;

  @media(min-width: 768px) {
    padding: 40px 70px 110px;
  }
`;

const VideoWrapper = styled.div`
  position: relative;
  max-width: 1300px;
  margin: 0 auto;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 50px;
  padding-top: 56.25%; // Player ratio: 100 / (1280 / 720)

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`;


const ContentWrapper = styled.div`
  max-width: 1300px;
  margin: 0 auto;
  /* padding-left: 30px; */
`;

const GalleryPage = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <Seo title={t('seo.gallery.title')} />
      <Content>
        <Section>
          <ContentWrapper>
            <LangSelector isDark={true} />
            <LeftSubtitle as="h1">{t('gallery.video')}</LeftSubtitle>
            <VideoWrapper className="video-container">
              <ReactPlayer
                className="react-player"
                url="https://vimeo.com/870598007"
                width="100%"
                height="100%"
                controls={true}
                />
            </VideoWrapper>
            <LeftSubtitleWithoutLine>{t('gallery.constructionProgress')}</LeftSubtitleWithoutLine>
            <GalleryGrid
              data={['02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '29', '28', '30', '31', '32'].reverse()}
              folder="construction"
              />
            <LeftSubtitleWithoutLine>{t('gallery.visualization')}</LeftSubtitleWithoutLine>
            <GalleryGrid
              data={['01', '02', '03', '04', '05', '06', '07', '08']}
              folder="visualization"
              />
          </ContentWrapper>
        </Section>
      </Content>
    </Layout>
  )
}

export default GalleryPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
